import React from 'react'
import CheckoutPersonalInfo from './CheckoutPersonalInfo'
import CheckoutExtras from './CheckoutExtras'
import CheckoutPromoCodes from './CheckoutPromoCodes'
import CheckoutPayment from './CheckoutPayment'
import CheckoutSummary from './CheckoutSummary'
import Breadcrumb from '../common/Breadcrumb'
import {AppView, IbeConfig} from '../../types'
import ShoppingCart from '../cart/ShoppingCart'

export interface CheckoutViewProps {
  appView: AppView
  config: IbeConfig
}

const CheckoutView = ({appView, config}: CheckoutViewProps) => {
  return (
    <section>
      <Breadcrumb appView={appView} />
      <section>
        <h1 className="text-xl font-bold">Checkout</h1>
        <div className="mt-4 flex flex-col lg:flex-row gap-8">
          <div className="flex flex-col gap-4 lg:w-1/2">
            <CheckoutPersonalInfo isActive={true} />
            {/* <CheckoutExtras isActive={true} /> */}
            {/* <CheckoutPromoCodes isActive={true} /> */}
            <CheckoutPayment isActive={true} />
          </div>
          <div className="order-first lg:order-none lg:w-1/2">
            {/* <CheckoutSummary /> */}
            <section
              className="px-4 pb-4 rounded-xl"
              style={{backgroundColor: '#f0f0f0'}}
            >
              <ShoppingCart />
            </section>
          </div>
        </div>
      </section>
    </section>
  )
}

export default CheckoutView
