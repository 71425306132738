import React from 'react'
import Badge from '../common/Badge'

export interface CheckoutPaymentProps {
  isActive: boolean
}

const CheckoutPayment = ({isActive}: CheckoutPaymentProps) => {
  return (
    <div className="border border-gray-300 rounded-xl p-4">
      <div className="flex flex-row gap-4">
        <Badge label="2" />
        <h1 className="font-bold">Payment</h1>
      </div>
      {isActive ? (
        <div className="mx-12 mt-2 flex flex-col gap-1">
          (Adyen Drop-in Goes Here)
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default CheckoutPayment
