import React from 'react'
import {createRoot} from 'react-dom/client'
import App from './components/App'
import {Provider} from 'react-redux'
import {store, persistor} from './store/store'
import {APIProvider} from '@vis.gl/react-google-maps'
import AppErrorBoundary from './components/AppErrorBoundary'
import {PersistGate} from 'redux-persist/integration/react'
import {getConfig} from './services/config'
import './index.css'

const {googleMapsApiKey} = getConfig()
const container = document.getElementById('appRoot')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <AppErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}></PersistGate>
        <APIProvider apiKey={googleMapsApiKey}>
          <App />
        </APIProvider>
      </Provider>
    </AppErrorBoundary>
  </React.StrictMode>
)
