import React from 'react'
import DatePicker from 'react-datepicker'
import {formatISO, parseISO} from 'date-fns'
import {
  IbeConfig,
  SearchGuestParams,
  SearchParams,
  SearchSpecialRatesParams
} from '../../types'
import SearchRoomsGuestsControl from './SearchRoomsGuestsControl'
import clsx from 'clsx'
import SearchSpecialRatesControl from './SearchSpecialRatesControl'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCalendar,
  faChevronDown,
  faLocationDot,
  faTags,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import {formatDate} from '../../services/utils'

export interface SearchBarProps {
  config: IbeConfig
  searchParams: SearchParams
  onSubmit: (searchParams: SearchParams) => void
}

const SearchBar = ({config, searchParams, onSubmit}: SearchBarProps) => {
  const [searchForm, setSearchForm] = React.useState<SearchParams>(searchParams)
  const {locations} = config

  const startDate = searchForm?.dateRange?.start
    ? parseISO(searchForm.dateRange.start)
    : null
  const endDate = searchForm?.dateRange?.end
    ? parseISO(searchForm.dateRange.end)
    : null

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        onSubmit(searchForm)
      }}
      className="bg-white border shadow-md rounded-xl"
    >
      <div
        className={clsx(
          'flex flex-col px-4 gap-0',
          'md:flex-row md:gap-8 md:shadow',
          'md:pt-1 md:pb-3'
        )}
      >
        <div
          className={clsx(
            'form-control w-full border-b pt-2 pb-4',
            'md:w-60 md:border-b-0 md:py-0'
          )}
        >
          <div className="text-sm text-cabana gap-2 py-2 px-0 flex flex-row items-center">
            <FontAwesomeIcon icon={faLocationDot} />
            <span className="label-text text-cabana">location</span>
          </div>
          <div className="flex flex-row items-center w-full">
            <select
              style={{
                appearance: 'none'
              }}
              className="flex-1 text-md font-bold border-0 focus:border-0 focus:ring-0 outline-none"
              value={searchForm.location}
              onChange={e => {
                setSearchForm({
                  ...searchForm,
                  location: e.target.value
                })
              }}
            >
              <option key="ALL" value="ALL">
                All Locations
              </option>
              {locations.map(({id, name}) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
            <FontAwesomeIcon icon={faChevronDown} className="flex-0 text-2xs" />
          </div>
        </div>

        <div
          className={clsx(
            'form-control w-full border-b pt-2 pb-4',
            'md:w-60 md:border-b-0 md:py-0'
          )}
        >
          <div className="text-sm text-cabana gap-2 py-2 px-0 flex flex-row items-center">
            <FontAwesomeIcon icon={faCalendar} />
            <span className="label-text text-cabana">arrival/departure</span>
          </div>
          <DatePicker
            className="font-bold text-md w-64 bg-transparent pt-1"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            dateFormat="MMM d, yyyy"
            minDate={new Date()}
            onChange={([start, end]: [Date | null, Date | null]) => {
              setSearchForm({
                ...searchForm,
                dateRange: {
                  type: 'Specific',
                  start: start ? formatISO(start) : undefined,
                  end: end ? formatISO(end) : undefined
                }
              })
            }}
          />
        </div>

        <button
          type="submit"
          className={clsx(
            'hidden',
            'md:block btn btn-secondary w-36 ml-auto mt-2'
          )}
        >
          Search
        </button>
      </div>

      <div
        className={clsx(
          'flex flex-col',
          'px-4 pt-0 mb-2 gap-0',
          'md:flex-row md:gap-8 md:pt-2',
          'md:bg-gradient-to-b md:from-gray-200 md:from-5%',
          'md:via-gray-100 md:via-10%',
          'md:to-white md:to-20%'
        )}
      >
        <div
          className={clsx(
            'form-control w-full border-b pt-2 pb-4',
            'md:w-60 md:border-b-0 md:pt-0 md:pb-2'
          )}
        >
          <div className="text-sm text-cabana gap-2 py-2 px-0 flex flex-row items-center">
            <FontAwesomeIcon icon={faUser} />
            <span className="label-text text-cabana">rooms & guests</span>
          </div>
          <div className="font-bold text-sm flex flex-row items-center">
            <SearchRoomsGuestsControl
              searchGuestParams={searchForm.guests}
              onChange={(searchGuestsParams: SearchGuestParams) => {
                setSearchForm({
                  ...searchForm,
                  guests: searchGuestsParams
                })
              }}
            />
          </div>
        </div>

        <div
          className={clsx('form-control w-full pt-2 pb-4', 'md:w-60 md:py-0')}
        >
          <div className="text-sm text-cabana gap-2 py-2 px-0 flex flex-row items-center">
            <FontAwesomeIcon icon={faTags} />
            <span className="label-text text-cabana">special rates</span>
          </div>
          <SearchSpecialRatesControl
            specialRates={searchForm.specialRates}
            onChange={(specialRates: SearchSpecialRatesParams) => {
              setSearchForm({
                ...searchForm,
                specialRates
              })
            }}
          />
        </div>

        <button
          type="submit"
          className={clsx('btn btn-secondary w-full my-2', 'md:hidden')}
        >
          Search
        </button>
      </div>
    </form>
  )
}

export default SearchBar
