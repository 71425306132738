import React, {useState} from 'react'
import SearchPropertyAmenities from './SearchPropertyAmenities'
import {OfferResult, PropertyConfig} from '../../types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faLocationCrosshairs,
  faLocationDot
} from '@fortawesome/free-solid-svg-icons'
import {formatPropertyAddress, getBestOffer} from '../../services/utils'
import ImageCarousel from '../common/ImageCarousel'
import SearchPropertyDetailsModal from './SearchPropertyDetailsModal'
import clsx from 'clsx'

export interface SearchPropertyResultsItemProps {
  result: OfferResult
  propertyConfig: PropertyConfig
  onSelectProperty: (propertyId: string) => void
  showMapLink?: boolean
  onShowOnMap?: (propertyId: string) => void
}

const SearchPropertyResultItem = ({
  result,
  propertyConfig,
  onSelectProperty,
  showMapLink = true,
  onShowOnMap = () => {}
}: SearchPropertyResultsItemProps) => {
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)
  const {property, offers} = result
  const addressString = formatPropertyAddress(propertyConfig)
  const bestOffer = getBestOffer(offers)
  const nights = bestOffer.timeSlices.length
  const totalAmount = bestOffer.totalGrossAmount.amount
  const nightlyRate = Math.round(totalAmount / nights)

  return (
    <>
      <div className="card bg-white shadow">
        <div className="flex flex-col md:flex-row">
          {/* image carousel */}
          <div
            className={clsx(
              'w-full overflow-hidden rounded-tl-2xl rounded-tr-2xl max-h-72',
              'md:w-5/12 md:rounded-bl-2xl md:rounded-tr-none md:max-h-none',
              'relative'
            )}
          >
            <ImageCarousel />
            <span className="badge badge-accent badge-lg absolute top-4 left-4">
              pop-up
            </span>
          </div>
          <div className="w-full md:w-7/12 flex flex-col">
            {/* property header */}
            <div className="w-full border-b flex flex-col p-4">
              <h1 className="text-xl font-bold">{property.name}</h1>
              <div className="flex flex-row gap-1 w-full">
                {/* property address */}
                <div className="flex-1 flex flex-row text-sm items-center gap-1">
                  <FontAwesomeIcon icon={faLocationDot} />
                  <div className="text-pretty">{addressString}</div>
                </div>
                {/* property map link */}
                {showMapLink && (
                  <div className="flex-0 flex flex-row items-center gap-4">
                    <a
                      className="link link-primary text-sm flex flex-row text-sm items-center gap-1"
                      onClick={() => {
                        onShowOnMap(propertyConfig.id)
                      }}
                    >
                      <FontAwesomeIcon icon={faLocationCrosshairs} />
                      <div>Show on Map</div>
                    </a>
                  </div>
                )}
              </div>
              {/* property amenities */}
              <SearchPropertyAmenities />
            </div>

            <div className="p-4 flex flex-col gap-2">
              {/* property description */}
              <div className="text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pellentesque hendrerit enim ac ornare. Quisque metus est, mattis
                at nunc et, vehicula euismod ligula. Nulla non enim eleifend,
                scelerisque orci et, luctus quam.
              </div>

              {/* property details modal link */}
              <a
                className="link link-primary text-sm"
                onClick={() => setIsDetailModalOpen(true)}
              >
                Property Details
              </a>

              <div className="flex flex-row gap-8 justify-end items-center mt-8 w-full">
                {/* property pricing */}
                <div className="flex flex-col justify-center items-center flex-none">
                  <div className="text-gray-400 text-xs leading-3">
                    starting at
                  </div>
                  <div className="font-bold">
                    <span className="text-xl leading-6 mr-1">
                      ${nightlyRate}
                    </span>
                    <span className="text-md uppercase">
                      {bestOffer.totalGrossAmount.currency}
                    </span>
                  </div>
                  <div className="text-gray-400 text-xs leading-3">
                    per night
                  </div>
                </div>

                {/* property select cta */}
                <button
                  className="btn btn-secondary w-36"
                  onClick={() => {
                    onSelectProperty(property.id)
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SearchPropertyDetailsModal
        propertyConfig={propertyConfig}
        isOpen={isDetailModalOpen}
        onClose={() => setIsDetailModalOpen(false)}
      />
    </>
  )
}

export default SearchPropertyResultItem
