import React from 'react'
import {formatCurrency} from '../../services/utils'
import {Service} from '../../types'
import clsx from 'clsx'

export interface ShoppingCartServiceItemProps {
  service: Service
  isSelected: boolean
  onSelectionChange: (isSelected: boolean) => void
  className?: string
}

const ShoppingCartServiceItem = ({
  service,
  isSelected,
  onSelectionChange,
  className = ''
}: ShoppingCartServiceItemProps) => {
  const {
    service: {name, description, pricingUnit},
    dates,
    totalAmount
  } = service
  const quantity = dates.length
  const pricePerUnit = dates[0].amount.grossAmount

  return (
    <div
      className={clsx(
        'card card-compact border bg-white shadow w-full',
        className
      )}
    >
      <div className="card-body flex flex-row gap-4 items-center ">
        <div className="w-12">
          <input
            type="checkbox"
            className="toggle"
            checked={isSelected}
            onChange={() => {
              onSelectionChange(!isSelected)
            }}
          />
        </div>
        <div className="flex-1">
          <h3 className="font-bold text-md md:text-lg">{name}</h3>
          <div className="text-xs md:text-sm">{description}</div>
        </div>
        <div className="w-28">
          <div className="flex flex-col gap-0 items-center">
            <span className="text-md md:text-lg leading-5">
              Quantity: {quantity}
            </span>
            <span className="lowercase text-xs text-gray-500 leading-3">
              {quantity > 1
                ? `${quantity} nights x ${formatCurrency(pricePerUnit)}`
                : '1x per stay'}
            </span>
          </div>
        </div>
        <div className="w-20">
          <div className="flex flex-col gap-0 items-end">
            <span className="font-bold text-md md:text-lg leading-5">
              {formatCurrency(totalAmount.grossAmount)}
            </span>
            <span className="lowercase text-xs text-gray-500 leading-3">
              per {pricingUnit}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingCartServiceItem
