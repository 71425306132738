import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'
import {SearchParams} from '../types'
import {addDays, format, formatISO} from 'date-fns'
import {formatDate} from '../services/utils'

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    location: 'WASHINGTON-DC',
    propertyId: 'WADCTEST06',
    dateRange: {
      type: 'Specific',
      start: formatISO(addDays(new Date(), 1)),
      end: formatISO(addDays(new Date(), 2))
    },
    guests: {
      rooms: 1,
      adults: 1,
      children: 0,
      hasInfants: false,
      hasPets: false
    },
    specialRates: {
      promoCode: '',
      corporateCode: ''
    },
    unitGroupTypes: ['BedRoom']
  } as SearchParams,
  reducers: {
    setSearch: (state, action: PayloadAction<SearchParams>) => {
      state = action.payload
      return state
    }
  }
})

// selectors
export const selectSearch = (state: RootState): SearchParams => {
  return state.search
}

// actions
export const {setSearch} = searchSlice.actions

// reducer
export default searchSlice.reducer
