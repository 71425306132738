import {Offer, Service, TaxAndFeeItem} from '../types'

export function calcTotalRoomCharges(offer: Offer): number {
  return (
    offer.timeSlices?.reduce(
      (acc, timeSlice) => acc + timeSlice.baseAmount.grossAmount,
      0
    ) || 0
  )
}

export function calcTotalServiceCharges(
  offer: Offer,
  selectedServiceIds: string[]
): number {
  return (
    offer.services?.reduce((acc, service) => {
      if (selectedServiceIds.includes(service.service.id)) {
        return acc + service.totalAmount.grossAmount
      }
      return acc
    }, 0) || 0
  )
}

export function calcTotalTaxesAndFees(
  offer: Offer,
  taxServiceCodes: string[]
): number {
  const totalFees =
    offer.fees?.reduce((acc, fee) => {
      return acc + fee.totalAmount.grossAmount
    }, 0) || 0

  const totalTaxServices =
    offer.services?.reduce((acc, service) => {
      if (taxServiceCodes.includes(service.service.code)) {
        acc += service.totalAmount?.grossAmount
      }
      return acc
    }, 0) || 0

  const totalCityTaxes =
    offer.cityTaxes?.reduce((acc, cityTax) => {
      return acc + cityTax.totalGrossAmount.amount
    }, 0) || 0

  // const taxDetails = offer.taxDetails || []
  // const totalTaxDetails = taxDetails.reduce((acc, taxDetail) => {
  //   return acc + taxDetail.tax.amount
  // }, 0)

  // const total = totalFees + totalTaxServices + totalCityTaxes + totalTaxDetails
  return totalFees + totalTaxServices + totalCityTaxes
}

export function calcTotalPrepaymentAmount(
  offer: Offer,
  additionalServices: string[]
): number {
  const servicesPrepayment =
    offer.services?.reduce((acc, service) => {
      if (
        additionalServices.includes(service.service.id) &&
        service.prePaymentAmount
      ) {
        return acc + service.totalAmount.grossAmount
      }
      return acc
    }, 0) || 0
  const roomPrepayment = offer.prePaymentAmount.amount
  return servicesPrepayment + roomPrepayment
}

export function calcTotalCharges(
  offer: Offer,
  additionalServices: string[],
  taxServiceCodes: string[]
): number {
  const totalRoomCharges = calcTotalRoomCharges(offer)
  const totalServiceCharges = calcTotalServiceCharges(offer, additionalServices)
  const totalTaxesAndFees = calcTotalTaxesAndFees(offer, taxServiceCodes)
  return totalRoomCharges + totalServiceCharges + totalTaxesAndFees
}

export function getAllTaxesAndFees(
  offer: Offer,
  taxServiceCodes: string[]
): TaxAndFeeItem[] {
  const {fees, services, cityTaxes} = offer

  const allFees =
    fees && fees.length > 0
      ? fees.map(fee => {
          return {
            name: fee.name,
            amount: fee.totalAmount.grossAmount,
            currency: fee.totalAmount.currency
          }
        })
      : []

  const allTaxServices =
    services && services.length > 0
      ? services.reduce((acc, service) => {
          if (taxServiceCodes.includes(service.service.code)) {
            return [
              ...acc,
              {
                name: service.service.name,
                amount: service.totalAmount.grossAmount,
                currency: service.totalAmount.currency
              }
            ]
          } else {
            return acc
          }
        }, [])
      : []

  const allCityTaxes =
    cityTaxes && cityTaxes.length > 0
      ? cityTaxes.map(cityTax => {
          return {
            name: cityTax.name,
            amount: cityTax.totalGrossAmount.amount,
            currency: cityTax.totalGrossAmount.currency
          }
        })
      : []

  // const taxDetails = offer.taxDetails || []
  // const allTaxDetails = taxDetails.map((taxDetail) => {
  //   return {
  //     name: taxDetail.,
  //     amount: taxDetail.tax.amount,
  //     currency: taxDetail.tax.currency
  //   }
  // })

  return [...allFees, ...allTaxServices, ...allCityTaxes]
}

export function getAdditionalServices(
  offer: Offer,
  selectedServiceIds: string[]
): Service[] {
  return (
    offer.services?.reduce((acc, service) => {
      if (selectedServiceIds.includes(service.service.id)) {
        return [...acc, service]
      }
      return acc
    }, []) || []
  )
}

export function hasAdditionalServices(offer: Offer): boolean {
  // TODO: make this more robust, ignore service-based taxes
  return offer.services?.length > 0
}
