import React from 'react'
import {selectCartItems} from '../../store/shoppingCartSlice'
import {useAppSelector} from '../../store/hooks'
import * as types from '../../types'
import ShoppingCartItem from './ShoppingCartItem'
import clsx from 'clsx'
import {formatCurrency} from '../../services/utils'
import {
  calcTotalCharges,
  calcTotalPrepaymentAmount
} from '../../services/offerUtils'
import {ca} from 'date-fns/locale'

export interface ShoppingCartProps {
  className?: string
}

const ShoppingCart = ({className = ''}: ShoppingCartProps) => {
  const cartItems = useAppSelector(selectCartItems)
  const cartTotals = cartItems.reduce(
    (acc, item) => {
      acc.totalCharges += calcTotalCharges(
        item.offer,
        item.additionalServices,
        []
      )
      acc.totalPrepayment += calcTotalPrepaymentAmount(
        item.offer,
        item.additionalServices
      )
      return acc
    },
    {totalCharges: 0, totalPrepayment: 0}
  )

  return (
    <div className={clsx('flex flex-col', className)}>
      <h2 className="mt-4 text-lg font-bold">Items:</h2>
      {!cartItems || cartItems.length === 0 ? (
        <div className="flex flex-col justify-center items-center h-96 border">
          <p className="font text-lg italic">Your cart is empty</p>
        </div>
      ) : (
        <>
          <div
            className={clsx(
              'flex-1 flex flex-col gap-2 overflow-y-scroll p-2 mt-2',
              'border border-gray-300 bg-gray-100 shadow-inner rounded-2xl'
            )}
            style={{maxHeight: '500px'}}
          >
            {cartItems.map((item: types.ShoppingCartItem, index: number) => (
              <ShoppingCartItem
                key={index}
                item={item}
                itemIndex={index}
                showActions={true}
                showItemIndex={cartItems?.length > 1}
                showPricingDetails={false}
              />
            ))}
          </div>

          <h2 className="mt-8 mb-2 text-lg font-bold">Summary:</h2>

          <div className="flex flex-row justify-end w-full">
            <table className="price-details">
              <tbody>
                <tr className="price-summary-header">
                  <td colSpan={2}>
                    Total Charges for {cartItems.length} Rooms
                  </td>
                </tr>
                <tr>
                  <td>Total Prepayment</td>
                  <td className="font-medium price">
                    {formatCurrency(cartTotals.totalPrepayment)}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">Total</td>
                  <td className="font-bold price">
                    {formatCurrency(cartTotals.totalCharges)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  )
}

export default ShoppingCart
