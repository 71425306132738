import React from 'react'
import {Offer} from '../../types'
import {format, parseISO} from 'date-fns'
import {formatDate, generateUUID} from '../../services/utils'
import PricingDetails from '../cart/ShoppingCartPricingDetails'

export interface SearchUnitOfferDetailsModalodalProps {
  offer: Offer
  isOpen: boolean
  onClose: () => void
}

const SearchUnitOfferDetailsModalodal = ({
  offer,
  isOpen,
  onClose
}: SearchUnitOfferDetailsModalodalProps) => {
  return (
    <>
      {isOpen && (
        <div
          className="modal modal-open"
          onClick={e => {
            if (e.target === e.currentTarget) {
              onClose()
            }
          }}
        >
          <div className="modal-box">
            <div className="modal-header">
              <button
                className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                onClick={onClose}
              >
                ✕
              </button>
              <h2 className="font-semibold text-xl">{offer.unitGroup.name}</h2>
              <h3 className="text-lg">{offer.ratePlan.name}</h3>
            </div>
            <div className="modal-body overflow-y-scroll max-h-96 pr-4 mt-2 py-1">
              {/* Pricing */}
              <h4 className="font-bold text-cabana mt-8 mb-4 border-b">
                Price Breakdown
              </h4>

              <table className="table table-compact border-collapse border border-gray-300">
                <thead>
                  <tr className="border border-gray-300">
                    <th className="border border-gray-300 font-bold">Date</th>
                    <th className="border border-gray-300 font-bold">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {offer.timeSlices.map((timeSlice, index) => {
                    return (
                      <tr key={index}>
                        <td className="border border-gray-300">
                          {formatDate(timeSlice.from)}
                        </td>
                        <td className="border border-gray-300 text-right">
                          <span className="font-bold mr-0.5">
                            ${timeSlice.totalGrossAmount.amount}
                          </span>
                          <span className="uppercase text-xs">
                            {timeSlice.totalGrossAmount.currency}
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                  <tr className="bg-gray-100">
                    <td className="border border-gray-300">Total:</td>
                    <td className="border border-gray-300 text-right">
                      <span className="font-bold mr-1">
                        ${offer.totalGrossAmount.amount}
                      </span>
                      <span className="uppercase text-xs">
                        {offer.totalGrossAmount.currency}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Service Pricing */}
              <h4 className="font-bold text-cabana mt-8 mb-4 border-b">
                Optional Services
              </h4>
              <table className="table table-compact border-collapse border border-gray-300">
                <thead>
                  <tr className="border border-gray-300">
                    <th className="border border-gray-300 font-bold">
                      Service
                    </th>
                    <th className="border border-gray-300 font-bold">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-gray-100">
                    <td className="border border-gray-300">Pet Fee</td>
                    <td className="border border-gray-300 text-right">
                      <span className="font-bold mr-1">$100</span>
                      <span className="uppercase text-xs">
                        {offer.totalGrossAmount.currency}
                      </span>
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <td className="border border-gray-300">
                      Parking (per night)
                    </td>
                    <td className="border border-gray-300 text-right">
                      <span className="font-bold mr-1">$50</span>
                      <span className="uppercase text-xs">
                        {offer.totalGrossAmount.currency}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h4 className="font-bold text-cabana mt-8 mb-4 border-b">
                Guarantee Policy
              </h4>
              <p>A valid payment method is required at the time of booking.</p>

              <h4 className="font-bold text-cabana mt-8 mb-4 border-b">
                Cancellation Policy
              </h4>
              <p>{offer.cancellationFee.description}</p>

              <h4 className="font-bold text-cabana mt-8 mb-4 border-b">
                No Show Policy
              </h4>
              <p>{offer.noShowFee.description}</p>
            </div>

            <div className="modal-action">
              <button className="btn btn-secondary" onClick={onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SearchUnitOfferDetailsModalodal
