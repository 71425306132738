import {useLazySearchOffersQuery} from '../services/ibeApi'
import {useState} from 'react'
import {OfferResult, SearchParamsRequest} from '../types'

export const useParallelSearchOffersQuery = () => {
  const [triggerFetch] = useLazySearchOffersQuery()
  const [offerResults, setOfferResult] = useState<OfferResult[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const triggerParallelFetch = async (
    parallelParams: SearchParamsRequest[]
  ) => {
    setLoading(true)
    setError(null)

    try {
      const promises = parallelParams.map(params => {
        return triggerFetch(params)
      })
      const responses = await Promise.all(promises)
      // const offerResults = responses.map(response => response.data)
      const offerResults = responses.reduce((acc: OfferResult[], response) => {
        if (response && response.data && response.data.offers?.length) {
          acc.push(response.data as OfferResult)
        }
        return acc
      }, [] as OfferResult[])
      setOfferResult(offerResults)
    } catch (err) {
      console.error('Error fetching offer results:', err)
      setError('Failed to fetch data')
    } finally {
      setLoading(false)
    }
  }

  return {
    offerResults,
    loading,
    error,
    triggerParallelFetch
  }
}
