import React, {useEffect, useRef} from 'react'
import clsx from 'clsx'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {SearchSpecialRatesParams} from '../../types'

export interface SearchSpecialRatesControlProps {
  specialRates: SearchSpecialRatesParams
  onChange: (updatedParams: SearchSpecialRatesParams) => void
  className?: string
}

interface FormState {
  hasPromoCode: boolean
  promoCode?: string
  hasCorporateCode: boolean
  corporateCode?: string
}

function getLabel({hasCorporateCode, hasPromoCode}: FormState): string {
  if (hasPromoCode && hasCorporateCode) {
    return 'Promo & Corporate Code'
  } else if (hasPromoCode) {
    return 'Promo Code'
  } else if (hasCorporateCode) {
    return 'Corporate Code'
  } else {
    return 'Best Available'
  }
}

const SearchSpecialRatesControl = ({
  specialRates,
  onChange,
  className = ''
}: SearchSpecialRatesControlProps) => {
  const [formState, setFormState] = React.useState<FormState>({
    hasPromoCode: specialRates?.promoCode ? true : false,
    promoCode: specialRates?.promoCode,
    hasCorporateCode: specialRates?.corporateCode ? true : false,
    corporateCode: specialRates?.corporateCode
  })

  const detailsRef = useRef<HTMLDetailsElement>(null)

  useEffect(() => {
    const {hasCorporateCode, hasPromoCode, corporateCode, promoCode} = formState
    onChange({
      ...specialRates,
      promoCode: hasPromoCode ? promoCode : '',
      corporateCode: hasCorporateCode ? corporateCode : ''
    })
  }, [formState])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        detailsRef.current &&
        !detailsRef.current.contains(event.target as Node)
      ) {
        detailsRef.current.removeAttribute('open')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <details ref={detailsRef} className={clsx('dropdown', className)}>
      <summary className="flex flex-row items-center ml-1 cursor-pointer">
        <div className="w-full font-bold text-md md:text-sm">
          {getLabel(formState)}
        </div>
        <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-2xs" />
      </summary>

      <div className="dropdown-content menu bg-white rounded-box z-[9999] w-64 p-4 mt-2 shadow flex flex-col gap-3">
        <div className="flex flex-col gap-2 pb-2 border-b">
          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text">Promo Code</span>
              <input
                type="checkbox"
                className="toggle toggle-sm"
                checked={formState.hasPromoCode}
                onChange={e => {
                  setFormState({
                    ...formState,
                    hasPromoCode: e.target.checked
                  })
                }}
              />
            </label>
          </div>
          {formState.hasPromoCode && (
            <div className="form-control">
              <input
                type="text"
                className="input input-sm focus:ring-0 outline-none"
                placeholder="Enter Promo Code"
                value={formState.promoCode}
                onChange={e => {
                  setFormState({
                    ...formState,
                    promoCode: e.target.value
                  })
                }}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text">Corporate Code</span>
              <input
                type="checkbox"
                className="toggle toggle-sm"
                checked={formState.hasCorporateCode}
                onChange={e => {
                  setFormState({
                    ...formState,
                    hasCorporateCode: e.target.checked
                  })
                }}
              />
            </label>
          </div>
          {formState.hasCorporateCode && (
            <div className="form-control">
              <input
                type="text"
                className="input input-sm focus:ring-0 outline-none"
                placeholder="Enter Corporate Code"
                value={formState.corporateCode}
                onChange={e => {
                  setFormState({
                    ...formState,
                    corporateCode: e.target.value
                  })
                }}
              />
            </div>
          )}
        </div>
      </div>
    </details>
  )
}

export default SearchSpecialRatesControl
