import React from 'react'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {
  addItem,
  hideItemEditor,
  selectIsItemEditorOpen,
  selectItemEditorMode,
  selectItemToEdit,
  updateItem,
  updateItemEditor
} from '../../store/shoppingCartSlice'
import ShoppingCartServiceItem from './ShoppingCartServiceItem'
import {AppView} from '../../types'
import ShoppingCartItem from './ShoppingCartItem'
import {setAppView} from '../../store/appViewSlice'
import {addToastNotification} from '../../store/toastNotificationSlice'
import {generateUUID} from '../../services/utils'
import clsx from 'clsx'

const ShoppingCartItemEditorModal = () => {
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(selectIsItemEditorOpen)
  const item = useAppSelector(selectItemToEdit)
  const editorMode = useAppSelector(selectItemEditorMode)
  const services = item?.offer?.services || []

  if (!item) {
    return null
  }

  return (
    <>
      {isOpen && (
        <div
          className="modal modal-open"
          onClick={e => {
            if (e.target === e.currentTarget) {
              dispatch(hideItemEditor())
            }
          }}
        >
          <div className="modal-box max-w-screen-lg max-h-[95%] w-[95%]">
            <h1 className="text-xl font-bold">
              {editorMode === 'Add' ? 'Add' : 'Edit'} Room
            </h1>
            <button
              className="btn btn-sm btn-circle btn-ghost absolute right-4 top-4"
              onClick={() => {
                dispatch(hideItemEditor())
              }}
            >
              ✕
            </button>

            {services.length > 0 && (
              <>
                <h2 className="text-lg font-semibold mt-4">
                  Choose Additional Services:
                </h2>
                <div
                  className={clsx(
                    'flex-1 flex flex-col gap-2 overflow-y-scroll p-2 mt-2',
                    'border border-gray-300 bg-gray-100 shadow-inner'
                  )}
                  style={{maxHeight: '500px'}}
                >
                  {services.map((service, index) => {
                    return (
                      <ShoppingCartServiceItem
                        key={index}
                        service={service}
                        isSelected={item.additionalServices.includes(
                          service.service.id
                        )}
                        onSelectionChange={(isSelected: boolean) => {
                          const updatedItem = {
                            ...item,
                            additionalServices: isSelected
                              ? [...item.additionalServices, service.service.id]
                              : item.additionalServices.filter(
                                  serviceId => serviceId !== service.service.id
                                )
                          }

                          dispatch(updateItemEditor(updatedItem))
                        }}
                      />
                    )
                  })}
                </div>
              </>
            )}

            <h2 className="text-lg font-semibold mt-4">Room Details:</h2>
            <ShoppingCartItem
              item={item}
              className="mt-2"
              showActions={false}
              showItemIndex={false}
              showPricingDetails={false}
            />

            <div>
              <div className="modal-action">
                {editorMode === 'Add' ? (
                  <>
                    <button
                      className="flex flex-col items-center gap-0 btn btn-ghost"
                      onClick={() => {
                        dispatch(hideItemEditor())
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="flex flex-col items-center gap-0 btn btn-ghost"
                      onClick={() => {
                        dispatch(addItem(item))
                        dispatch(hideItemEditor())
                        // show toast notification
                        dispatch(
                          addToastNotification({
                            id: generateUUID(),
                            type: 'success',
                            message: 'Added room to shopping cart.'
                          })
                        )
                      }}
                    >
                      Add Room
                    </button>

                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        dispatch(addItem(item))
                        dispatch(hideItemEditor())
                        // show toast notification
                        dispatch(
                          addToastNotification({
                            id: generateUUID(),
                            type: 'success',
                            message: 'Added room to shopping cart.'
                          })
                        )
                        dispatch(setAppView(AppView.CHECKOUT))
                      }}
                    >
                      Add Room & Checkout
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-ghost"
                      onClick={() => {
                        dispatch(hideItemEditor())
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        dispatch(updateItem(item))
                        dispatch(hideItemEditor())
                      }}
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ShoppingCartItemEditorModal
