import React, {useEffect} from 'react'
import CheckoutView from './checkout/CheckoutView'
import SearchView from './search/SearchView'
import {useGetConfigQuery} from '../services/ibeApi'
import {AppView, IbeConfig} from '../types'
import {useAppSelector} from '../store/hooks'
import {selectAppView, selectIsSearchView} from '../store/appViewSlice'
import ShoppingCartButton from './cart/ShoppingCartButton'
import ShoppingCartModal from './cart/ShoppingCartModal'
import useInitSearchParams from '../hooks/useInitSearchParams'
import ShoppingCartItemEditorModal from './cart/ShoppingCartItemEditorModal'
import ToastNotificationManager from './common/ToastNotificationManager'

const App = () => {
  // useInitSearchParams()
  const appView = useAppSelector(selectAppView)
  const isSearchView = useAppSelector(selectIsSearchView)
  const configQuery = useGetConfigQuery(null)
  const config = configQuery.data as IbeConfig

  useEffect(() => {
    // scroll up when view changes
    window.scrollTo(0, 0)
  }, [appView])

  return (
    <section className="placemakr-ibe p-4 sm:p-8 max-w-screen-xl mx-auto">
      {configQuery.isLoading && <div>Loading...</div>}
      {!configQuery.isLoading && config && (
        <>
          {/* Header */}
          <header className="flex flex-row items-center mb-4 w-full">
            <h1 className="flex-1 text-4xl font-serif">IBE v2</h1>

            {/* Shopping Cart Button */}
            {appView !== AppView.CHECKOUT && (
              <ShoppingCartButton className="flex-none" />
            )}
          </header>

          <ToastNotificationManager />

          {/* Search View */}
          {isSearchView && <SearchView appView={appView} config={config} />}

          {/* Checkout View */}
          {appView === AppView.CHECKOUT && (
            <CheckoutView appView={appView} config={config} />
          )}

          {/* Shopping Cart */}
          <ShoppingCartModal />

          {/* Shopping Cart Item Editor */}
          <ShoppingCartItemEditorModal />
        </>
      )}
      {!configQuery.isLoading && !config && (
        <div>Error: Unable to load configuration</div>
      )}
    </section>
  )
}

export default App
