import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'
import {ItemEdit, ItemEditorMode, ShoppingCartItem} from '../types'

export interface ShoppingCartState {
  items: ShoppingCartItem[]
  isCartOpen: boolean
  isItemEditorOpen: boolean
  itemEditorMode: ItemEditorMode
  itemToEdit: ShoppingCartItem | null
}

const initialState: ShoppingCartState = {
  items: [],
  isCartOpen: false,
  isItemEditorOpen: false,
  itemEditorMode: 'Add',
  itemToEdit: null
}

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    showCart: (state: ShoppingCartState) => {
      return {
        ...state,
        isItemEditorOpen: false,
        isCartOpen: true
      }
    },
    hideCart: (state: ShoppingCartState) => {
      return {
        ...state,
        isCartOpen: false
      }
    },
    addItem: (
      state: ShoppingCartState,
      action: PayloadAction<ShoppingCartItem>
    ) => {
      return {
        ...state,
        items: [...state.items, action.payload]
      }
    },
    updateItem: (
      state: ShoppingCartState,
      action: PayloadAction<ShoppingCartItem>
    ) => {
      const index = state.items.findIndex(item => item.id === action.payload.id)
      if (index >= 0) {
        state.items[index] = action.payload
      }
    },
    removeItem: (state: ShoppingCartState, action: PayloadAction<string>) => {
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload)
      }
    },
    showItemEditor: (
      state: ShoppingCartState,
      action: PayloadAction<ItemEdit>
    ) => {
      return {
        ...state,
        isCartOpen: false,
        isItemEditorOpen: true,
        itemEditorMode: action.payload?.mode,
        itemToEdit: action.payload.item
      }
    },
    updateItemEditor: (
      state: ShoppingCartState,
      action: PayloadAction<ShoppingCartItem>
    ) => {
      return {
        ...state,
        itemToEdit: action.payload
      }
    },
    hideItemEditor: (state: ShoppingCartState) => {
      return {
        ...state,
        isItemEditorOpen: false,
        itemToEdit: null
      }
    },
    clear: (state: ShoppingCartState) => {
      return {
        ...state,
        items: []
      }
    }
  }
})

// selectors
export const selectCartItems = (state: RootState): ShoppingCartItem[] => {
  return state.shoppingCart?.items || []
}

export const selectCartItemCount = (state: RootState): number => {
  return state.shoppingCart?.items?.length || 0
}

export const selectIsCartOpen = (state: RootState): boolean => {
  return state.shoppingCart?.isCartOpen || false
}

export const selectIsItemEditorOpen = (state: RootState): boolean => {
  return state.shoppingCart?.isItemEditorOpen || false
}

export const selectItemToEdit = (state: RootState): ShoppingCartItem | null => {
  return state.shoppingCart?.itemToEdit
}

export const selectItemEditorMode = (state: RootState): ItemEditorMode => {
  return state.shoppingCart?.itemEditorMode
}

// actions
export const {
  showCart,
  hideCart,
  addItem,
  updateItem,
  removeItem,
  showItemEditor,
  updateItemEditor,
  hideItemEditor,
  clear
} = shoppingCartSlice.actions

// reducer
export default shoppingCartSlice.reducer
