import React from 'react'
import Badge from '../common/Badge'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'

export interface CheckoutPersonalInfoProps {
  isActive: boolean
}

const PersonalInfoSchema = z.object({
  firstName: z.string().min(1, 'First Name is required'),
  lastName: z.string().min(1, 'Last Name is required'),
  phone: z.string().min(1, 'Mobile Phone is required'),
  email: z.string().min(1, 'Email is required').email(),
  comments: z.string().optional()
})

type PersonalInfo = z.infer<typeof PersonalInfoSchema>

const CheckoutPersonalInfo = ({isActive}: CheckoutPersonalInfoProps) => {
  const {register, handleSubmit, watch, formState} = useForm<PersonalInfo>({
    resolver: zodResolver(PersonalInfoSchema)
  })

  const {errors} = formState

  return (
    <div className="border border-1 border-gray-300 rounded-xl p-4 ">
      <div className="flex flex-row gap-4">
        <Badge label="1" />
        <h1 className="font-bold">Personal Information</h1>
      </div>
      {isActive ? (
        <form
          className="mx-12 mt-2 flex flex-col gap-1"
          onSubmit={handleSubmit((data: PersonalInfo) => {
            console.log('personalInfo', data)
          })}
        >
          <label className="form-control">
            <div className="label">
              <span className="label-text">First Name*</span>
            </div>
            <input
              type="text"
              className="input input-bordered input-sm"
              {...register('firstName')}
            />
            {errors.firstName ? (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {errors.firstName.message}
                </span>
              </div>
            ) : (
              ''
            )}
          </label>
          <label className="form-control">
            <div className="label">
              <span className="label-text">Last Name*</span>
            </div>
            <input
              type="text"
              className="input input-bordered input-sm"
              {...register('lastName')}
            />
            {errors.lastName ? (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {errors.lastName.message}
                </span>
              </div>
            ) : (
              ''
            )}
          </label>
          <label className="form-control">
            <div className="label">
              <span className="label-text">Mobile Phone*</span>
            </div>
            <input
              type="text"
              className="input input-bordered input-sm"
              {...register('phone')}
            />
            {errors.phone ? (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {errors.phone.message}
                </span>
              </div>
            ) : (
              ''
            )}
          </label>
          <label className="form-control">
            <div className="label">
              <span className="label-text">Email*</span>
            </div>
            <input
              type="text"
              className="input input-bordered input-sm"
              {...register('email')}
            />
            {errors.email ? (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {errors.email.message}
                </span>
              </div>
            ) : (
              ''
            )}
          </label>
          <label className="form-control">
            <div className="label">
              <span className="label-text">Comments about your stay</span>
            </div>
            <textarea className="textarea textarea-bordered"></textarea>
          </label>
          <div className="flex flex-row gap-2 my-4">
            <button type="submit" className="btn btn-secondary flex-1">
              Check-out as Member
            </button>
            <button type="submit" className="btn btn-secondary flex-1">
              Check-out as Guest
            </button>
          </div>
        </form>
      ) : (
        ''
      )}
    </div>
  )
}

export default CheckoutPersonalInfo
